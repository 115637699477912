<template>
  <div class="d-flex flex-column">

    <header class="notice-group d-flex flex-column">
      <v-carousel
        cycle
        height="390"
        hide-delimiters
        show-arrows-on-hover
      >
        <v-carousel-item v-for="(slide, i) in slides" :key="i">
          <v-sheet :color="colors[i]" height="100%">
            <v-row class="fill-height" align="center" justify="center">
              <div class="text-h2">
                {{ slide }}
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <div class="d-flex justify-center" style="margin-top: -12px; width: 100%; background-color: #FFFFFF">
        <div class="d-flex align-center" style="width: 1200px; height: 45px;">
          <div class="notice d-flex grow">
            <div class="label d-flex justify-center align-center">公告</div>
            <div class="d-flex align-center ml-8" style="color: #999">关于 GaPa 第十期新品优先购白名单公示</div>
          </div>
          <div>
            <v-btn small text color="#666666" @click="() => jump_to('newNotice')">查看更多</v-btn>
          </div>
        </div>
      </div>
    </header>

    <div class="d-flex flex-column pa-0 mt-15 align-self-center" style="width: 1200px">

      <div class="top-product d-flex justify-space-between">
        <div class="left">
          <v-img
            height="100%" style="border-radius: 10px; cursor: pointer" alt="top"
            :src="topCollage.picture"
            @click="() => to_detail(topCollage.id)"
          />
        </div>

        <div class="right d-flex flex-column justify-space-between">
          <div class="content d-flex flex-column justify-space-between">
            <span class="name" style="color: #2C2C34; font-size: 60px">{{ topCollage.typeName }}</span>
            <div class="avatar d-flex">
              <v-avatar class="d-flex" size="44">
                <v-img
                  src="https://static.ibox.art/file/oss/test/image/nft-goods/60811c1fdcbd46818c71f11bbe9ccbcd.png"/>
              </v-avatar>
              <div class="d-flex flex-column ml-4 justify-space-between">
                <span style="font-size: 14px; color: #84888F">创作者</span>
                <span class="d-flex"
                      style="height: 16px; font-size: 16px; color: #14181F"
                >{{ topCollage.autherName }}</span>
              </div>
            </div>
            <div class="detail d-flex flex-column">
              <span style="font-size: 16px">寄售价格</span>
              <span class="mt-4" style="font-size: 36px">￥ {{ topCollage.price }}</span>
              <span class="mt-4" style="font-size: 16px">商品描述</span>
              <span class="mt-4" style="font-size: 14px; color: #84888F">先祖图腾、鞭牛迎春、孺子精神……中华民族牛文化赋予牛各种象征意义的佳话、神话、风俗、典故广为流传。从古至今，牛都是正直、勤劳的代表，占据了无可超越的地位，牛能鼓舞人们积极向上，对...</span>
            </div>
          </div>
          <v-btn class="buy-btn d-flex justify-space-between" @click="() => to_detail(topCollage.id)">
            立即购买
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="d-flex flex flex-column" style="margin-top: 100px;">
        <v-row>
          <v-col
            md="4"
            lg="4"
            v-for="(item, i) in list"
            :key="i"
          >
            <v-card height="512px" class="flex flex-column">
              <v-img
                height="412px" style="cursor: pointer"
                :src="item.picture"
                @click="() => to_detail(topCollage.id)"
              />
              <div class="d-flex flex-column justify-space-between pa-4" style="height: 100px">
                <div style="font-size: 18px">{{ item.seriesName }}#{{ item.typeName }}</div>
                <div class="d-flex justify-space-between">
                  <div class="d-flex align-center mt-4">
                    <v-avatar class="d-flex flex-column" size="26">
                      <v-img
                        src="https://static.ibox.art/file/oss/test/image/nft-goods/60811c1fdcbd46818c71f11bbe9ccbcd.png"/>
                    </v-avatar>
                    <div
                      class="ml-4"
                      style="color: #84888F; font-size: 14px; line-height: 14px">
                      {{ item.autherName }}
                    </div>
                  </div>
                  <v-btn
                    class="d-flex mt-1"
                    outlined
                    @click="() => to_detail(item.id)"
                  >￥ {{ item.price }}
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-btn
        class="more-btn align-self-center mt-15"
        large
        outlined
        @click="() => jump_to('newProduct')"
      >查看更多
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data () {
    return {
      topCollage: {},
      list: [],
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
    }
  },

  async mounted () {
    const { code, message, data } = await this.$request.market.search(0, 13)
    if (code !== 0) {
      this.$notice.error(message)
      return
    }
    this.topCollage = data.shift()
    this.list = data
  },

  methods: {
    to_detail (id) {
      this.$router.push({ name: 'marketDetail', query: { id } })
    },
    jump_to (name) {
      this.$router.push({ name })
    },
  },
}
</script>

<style scoped lang="scss">
.notice {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, serif;

  .label {
    width: 68px;
    height: 34px;
    background-color: #2469E5;
    color: #FFFFFF;
  }
}

.top-product {
  height: 700px;

  .left {
    width: 630px;
  }

  .right {
    width: 488px;

    .content {
      height: 500px;

      .detail {
        padding: 32px;
        background-color: #FFFFFF;
        border-radius: 20px;
        color: #2C2C34;
        box-shadow: 0 10px 24px 0 rgba(1, 0, 95, 0.1);
      }
    }

    .buy-btn {
      padding: 0 24px;
      height: 64px;
      background-color: #141414;
      color: #FFFFFF;
      font-size: 20px;
      border-radius: 10px;
    }
  }
}

.more-btn {
  width: 380px;
  border-radius: 10px;
}
</style>
