import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast, { TYPE } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import vuetify from './plugins/vuetify'
import request from '@/assets/api/request'

Vue.config.productionTip = false

Vue.use(Toast)
Vue.prototype.$notice = {
  success (content, options) {
    const def = { type: TYPE.INFO, timeout: 2000 }
    Vue.$toast(content, { ...def, ...options })
  },
  warn (content, options) {
    const def = { type: TYPE.WARNING, timeout: 5000 }
    Vue.$toast(content, { ...def, ...options })
  },
  error (content, options) {
    const def = { type: TYPE.ERROR, timeout: 5000 }
    Vue.$toast(content, { ...def, ...options })
  },
}

Vue.prototype.$request = request

Vue.prototype.$scrollToTop = () => {
  window.scroll(0, 0)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
