import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    showLoginDialog: false,
  },
  getters: {},
  mutations: {
    setUserInfo (state, v) {
      state.userInfo = v
    },
    setShowLoginDialog (state, v) {
      state.showLoginDialog = v
    },
  },
  actions: {},
  modules: {},
})
