<template>
  <v-app style="background-color: #F8FBFF">

    <v-dialog
      :value="showLoginDialog"
      @input="(v) => setShowLoginDialog(v)"
      width="500"
    >
      <v-card class="pa-12">
        <v-card-title class="d-flex justify-center text-h5 font-weight-bold">登录</v-card-title>
        <v-card-text class="d-flex flex-column align-center">
          <v-text-field
            label="手机号 +86"
            hide-details="auto"
            v-model="loginPhone"
            required
            :rules="[ v => !!v || '请输入手机号' ]"
            oninput="if (this.value.length > 11) this.value = this.value.substr(0, 11)"
            style="width: 100%"
          />
          <div class="d-flex align-center justify-space-between mt-7" style="width: 100%">
            <v-otp-input
              style="width: 250px"
              class="mt-7 mb-10"
              v-model="loginCode"
              plain
              :disabled="!loginCodeBtnDowned"
              length="6"
            />
            <div class="ml-4" style="width: 100px">
              <v-btn v-if="loginCodeBtnCountDown === 0" color="blue" block small text @click="pressSendCode">发送验证码
              </v-btn>
              <v-btn v-else disabled small block outlined>{{ loginCodeBtnCountDown }}</v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            :loading="loginBtnLoading"
            color="primary"
            @click="dealLogin"
          >
            登录
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex justify-center" style="border-bottom: 1px solid rgba(0, 0, 0, .1)">
      <div class="d-flex align-center justify-space-between" style="width: 1200px; height: 80px;">
        <span class="logo">GaPa.art</span>
        <div class="d-flex search align-center ml-16">
          <v-text-field
            label="搜索藏品"
            class="pt-7"
            filled
            prepend-inner-icon="mdi-magnify"
            solo
            flat
            background-color="transparent"
            rounded
            outlined
          ></v-text-field>
        </div>

        <v-spacer></v-spacer>

        <div class="d-flex align-center">
          <v-tabs background-color="transparent">
            <v-tab v-for="tab in tabs" :key="tab.name" @click="() => tabChange(tab.name)">{{ tab.value }}</v-tab>
          </v-tabs>

          <div class="ml-10">
            <v-btn v-if="!userInfo || !userInfo.username" color="#141414" rounded @click="pressLogin">
              <span style="color: #FFFFFF">登录</span>
            </v-btn>
            <v-avatar v-else>
              <img :src="userAvatar" alt="avatar"/>
            </v-avatar>
          </div>
        </div>
      </div>
    </div>

    <v-main>
      <router-view/>
    </v-main>

    <div class="footer" style="width: 100%; margin-top: 120px">
      <div style="width: 1200px">
        <div class="box-1">
          <span class="logo">GaPa.art</span>
          <div class="min-box">
            <span class="help">帮助中心</span>
            <span class="other">平台通知</span>
            <span class="activity">平台活动</span>
            <span class="course">教程帮助</span>
          </div>
          <span class="cooperation">合作伙伴</span>
          <span class="about">关于我们</span>
        </div>
        <div class="box-2">
          <div class="keep-on-record">粤 ICP 备 202206xxxx 号 | 增值电信业务经营许可证 (EDI)</div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    userAvatar: 'https://static.ibox.art/file/oss/test/image/portrait/3842ff4f575e44a0bd42db5a798ddd99.png?style=st',
    loginPhone: null,
    loginCode: null,
    loginBtnLoading: false,
    loginCodeBtnDowned: false,
    loginCodeBtnCountDown: 0,

    tabs: [
      { name: 'home', value: '首页' },
      { name: 'newProduct', value: '新品' },
      { name: 'secondaryMarket', value: '市场' },
      { name: 'newNotice', value: '公告' },
      { name: 'userHome', value: '我的' },
    ],
  }),

  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    showLoginDialog () {
      return this.$store.state.showLoginDialog
    },
  },

  mounted () {
    this.$store.commit('setUserInfo', this.$request.getUserInfo())
  },

  watch: {
    loginCodeBtnCountDown (val) {
      if (val > 0) {
        setTimeout(() => {
          this.loginCodeBtnCountDown--
        }, 1000)
      }
    },
  },

  methods: {
    pressLogin () {
      this.setShowLoginDialog(true)
    },

    async dealLogin () {
      this.loginBtnLoading = true
      const { code, message, data } = await this.$request.user.login(this.loginPhone, this.loginCode)
      this.loginBtnLoading = false

      if (code !== 0) {
        this.$notice.error(message)
        return
      }

      this.$request.setToken(data)
      this.$notice.success('登录成功')

      setTimeout(() => {
        this.setShowLoginDialog(false)
        this.$router.go(0)
      }, 2000)
    },

    pressSendCode () {
      this.loginCodeBtnCountDown = 60
      this.loginCodeBtnDowned = true
    },

    tabChange (name) {
      if (this.$route.name !== name) {
        this.$router.push({ name })
      }
    },

    setShowLoginDialog (b) {
      this.$store.commit('setShowLoginDialog', b)
    },
  },
}
</script>

<style scoped lang="scss">
.logo {
  font-family: 'SourceHanSansCN-Bold', serif;
  font-size: 34px;
  font-weight: bold;
}

.footer {
  /* Footer */

  position: static;
  left: 0px;
  top: 2580px;
  width: 100%;
  height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0px;
  align-self: stretch;
  background: #FFFFFF;

  .box-1 {
    /* Content */

    position: static;
    left: 360px;
    top: 60px;
    width: 61%;
    height: 128px;
    display: flex;
    justify-content: space-between;
    padding: 0px;

    .min-box {
      /* 帮助中心 */

      position: static;
      left: 434px;
      top: 0px;
      width: 64px;
      height: 128px;
      display: flex;
      flex-direction: column;
      padding: 0px;

      .help {
        /* 帮助中心 */

        position: static;
        left: 0px;
        top: 0px;
        width: 64px;
        height: 32px;
        display: flex;
        font-family: SourceHanSansCN-Bold;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0em;
        color: #3D3D3D;
      }

      .other {
        /* 平台通知 */

        position: static;
        left: 0px;
        top: 38px;
        width: 56px;
        height: 32px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #999999;
      }

      .activity {
        /* 平台活动 */

        position: static;
        left: 0px;
        top: 73px;
        width: 56px;
        height: 32px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #999999;
      }

      .course {
        /* 教程帮助 */

        position: static;
        left: 0px;
        top: 108px;
        width: 56px;
        height: 32px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #999999;
      }
    }

    .logo {
      /* Logo */

      position: static;
      left: 0px;
      top: 0px;
      width: 147px;
      height: 49px;
      font-family: SourceHanSansCN-Bold;
      font-size: 34px;
      font-weight: bold;
      letter-spacing: 0em;
      color: #3D3D3D;
    }

    .cooperation {
      /* 合作伙伴 */

      position: static;
      left: 785px;
      top: 0px;
      width: 64px;
      height: 23px;
      font-family: SourceHanSansCN-Bold;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0em;
      color: #3D3D3D;
    }

    .about {
      /* 关于我们 */
      position: static;
      left: 1136px;
      top: 0px;
      width: 64px;
      height: 23px;
      font-family: SourceHanSansCN-Bold;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0em;
      color: #3D3D3D;
    }
  }

  .box-2 {
    /* 备案信息 */

    position: static;
    left: 360px;
    top: 233px;
    width: 61%;
    height: 17px;
    display: flex;
    justify-content: space-between;
    padding: 0px;

    .keep-on-record {
      position: static;
      left: 0px;
      top: 0px;
      width: 340px;
      height: 17px;
      font-family: SourceHanSansCN-Bold;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}
</style>
