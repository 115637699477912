import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/pages/Homepage'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage,
    },
    {
      path: '/new-product',
      name: 'newProduct',
      component: () => import('@/pages/Newproduct/NewProduct.vue'),
    }, {
      path: '/new-product/detail',
      name: 'newProductDetail',
      component: () => import('@/pages/Newproduct/NewProductDetail'),
    },
    {
      path: '/market',
      name: 'secondaryMarket',
      component: () => import('@/pages/Market/SecondaryMarket.vue'),
    }, {
      path: '/market/detail',
      name: 'marketDetail',
      component: () => import('@/pages/Market/MarketDetail'),
    },
    {
      path: '/new-notice',
      name: 'newNotice',
      component: () => import('@/pages/Newnotice/NewNotice.vue'),
    },
    {
      path: '/home',
      name: 'userHome',
      component: () => import('@/pages/UserHome'),
      redirect: '/home/collage',
      children: [
        {
          path: 'collage',
          name: 'userHomeCollage',
          component: () => import('@/pages/UserHome/UserHomeCollage'),
        },
        {
          path: 'wallet',
          name: 'userHomeWallet',
          component: () => import('@/pages/UserHome/UserHomeWallet'),
        },
        {
          path: 'order',
          name: 'userHomeOrder',
          component: () => import('@/pages/UserHome/UserHomeOrder'),
        },
        {
          path: 'coming-soon',
          name: 'userHomeComingSoon',
          component: () => import('@/components/ComingSoon'),
        },
      ],
    },

  ],
})

export default router
