import axios from 'axios'
import store from '@/store'
import jwtDecode from 'jwt-decode'

function setLocalStorage (key, value) {
  localStorage.setItem(key, value)
}

function getLocalStorage (key) {
  return localStorage.getItem(key)
}

const tokenUtils = {
  setToken (token) {
    store.commit('setUserInfo', jwtDecode(token))
    setLocalStorage('token', token)
  },
  getToken () {
    return getLocalStorage('token')
  },
  cleanToken () {
    store.commit('setUserInfo', {})
    localStorage.removeItem('token')
  },
  getUserInfo () {
    return jwtDecode(this.getToken(), { body: true })
  },
}

const PREFIX = '/api'

async function requestResponseInterceptor (request) {
  let rst = null
  await request
    .then(res => {
      rst = res
    })
    .catch(err => {
      rst = err.response
      if (rst.status === 401) {
        rst.data.message = '请先登录'
        store.commit('setUserInfo', {})
        store.commit('setShowLoginDialog', true)
      }
    })
  return rst.data
}

async function get (url, params) {
  return requestResponseInterceptor(axios.get(PREFIX + url,
    {
      params,
      headers: { Authorization: tokenUtils.getToken() },
    }))
}

async function post (url, data, params) {
  return requestResponseInterceptor(axios.post(PREFIX + url, data,
    {
      params,
      headers: { Authorization: tokenUtils.getToken() },
    }))
}

export default {
  newProduct: {
    search (page, pageSize, search = '') {
      return get('/new-product', { page, pageSize, search })
    },
    detail (id) {
      return get(`/new-product/${id}`)
    },
    buy (id) {
      return post('/new-product/buy', {}, { id })
    },
  },

  market: {
    search (page, pageSize, search = '') {
      return get('/market', { page, pageSize, search })
    },
    detail (id) {
      return get(`/market/${id}`)
    },
    buy (id) {
      return post('/market/buy', {}, { id })
    },
  },

  user: {
    login (phone, code) {
      return post('/user/login', { phone, code })
    },
    info () {
      return get('/user/info')
    },
  },

  collage: {
    list (page, pageSize) {
      return get('/collage', { page, pageSize })
    },
    consign (collageId, price) {
      return post('/collage/consign', {}, { collageId, price })
    },
  },

  wallet: {
    info () {
      return get('/wallet/info')
    },
    topUp (amount) {
      return post('/wallet/top-up', {}, { amount })
    },
  },

  order: {
    list (page, pageSize) {
      return get('/order', { page, pageSize })
    },
  },

  setToken (token) {
    tokenUtils.setToken(token)
  },
  getToken () {
    return tokenUtils.getToken()
  },
  cleanToken () {
    tokenUtils.cleanToken()
  },
  getUserInfo () {
    return tokenUtils.getUserInfo()
  },
}
